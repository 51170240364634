<template>
  <div class="welcome">
    <!-- <img src="@/assets/welcome.jpg" alt="" /> -->
    <van-swipe :autoplay="5000" lazy-render touchable>
      <van-swipe-item v-for="image in imageList" :key="image.id">
        <img :src="image.fullFilePath" />
      </van-swipe-item>
    </van-swipe>
    <input
      type="text"
      ref="input"
      class="box"
      v-model="KeyCard"
      @keyup.enter="swipingCard"
    />
    <!-- <div class="code flex flex-center">手牌感应区</div> -->
  </div>
</template>

<script>
export default {
  name: "welcome",
  data() {
    return {
      KeyCard: "",
      timer: null,
      imageList:[]
    };
  },
  watch: {
    KeyCard: {
      handler: function (val, oldVal) {
        this.$refs.input.focus();
      },
      deep: true,
    },
  },
  created() {
    // this.getConsumptionAreaId();
    
  },
  mounted() {
    this.$refs.input.focus();
    this.getStoreSetting()
  },
  methods: {
    swipingCard() {
      if(this.KeyCard){
        this.getSingleByCode(this.KeyCard);
      }
      this.KeyCard = "";
      this.$refs.input.focus();
    },
    getStoreSetting(){
      this.$fetchNo(this.Api.getStoreSetting).then(res =>{
        if(res.data.storeSettingFiles.length>0){
          this.imageList = res.data.storeSettingFiles.filter(item => item.fileBusinessType == 2).sort((a, b) => a.sort - b.sort)
        }
        console.log(this.imageList)
      })
    },
    getSingleByCode(KeyCard) {
      this.$fetchNo(this.Api.examineKeyCard, { KeyCard: KeyCard }).then(
        (res) => {
          if (res.data.length > 0) {
            const { consumptionSubjectId, keyCard } = res.data[0];
            localStorage.setItem("consumptionSubjectId", consumptionSubjectId);
            localStorage.setItem("keyCard", keyCard);
            this.$router.push("/list");
            // this.$router.push("/home");
          }else{
            this.$toast('该手牌暂无数据，请更换手牌')
          }
        }
      );
    },
    getConsumptionAreaId() {
      this.$fetchNo(this.Api.userInfo).then((res) => {
        let dataList = res.data.filter((item) => item.isDefault);
        if (dataList.length > 0) {
          localStorage.setItem(
            "consumptionAreaId",
            dataList[0].consumptionAreaId
          );
        } else {
          localStorage.setItem(
            "consumptionAreaId",
            res.data[0].consumptionAreaId
          );
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.welcome {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    background: transparent;
    font-size: 1px;
    color: transparent;
  }
  img {
    width: 100vw;
    height: 100vh;
  }
  .code {
    width: 886px;
    height: 140px;
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 110px;
  }
}
</style>